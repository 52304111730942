import React, { Component } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
    updateUserProfileAction, updateUserProfileActionWithoutBranch,
    getLanguageDataAction, clearStoreData, getEntityDetails, createMandate
} from '../../redux/actions'
import { SimpleUserForm } from '../../components/Forms/PaymentForm'
import UserInfo from '../../components/SideBar/UserInfo.component';
import {
    success as successNotificationAction,
} from 'react-notification-system-redux';

import { notificationOpts } from '../../config'
//session expiry modal
import SessionExpiryModal from './../../components/SessionExpiryModal'
import config from "./../../config/config";
import loadScript from './Payments'
import { GetSessionValue } from '../../utils/sessionStorage';
import ModalComponent from '../../components/Modal'

let paymentSuccess = true;
let languageData = {}
class UserProfile extends Component {
    state = {
        isDisabled: true,
        btnLabel: 'Update Profile',
        userDetails: '',
        sessionExpiryModal: false,
        apiLoaded: false,
        paymentSuccess: true,
        showSkipPayment : false,
        skipPaymentMessage : ""
    }
    loadScript() {
        // Load the google maps api script when the component is mounted.

        loadScript("https://dropin.gocardless.com/script.js")
            .then((script) => {
                // Grab the script object in case it is ever needed.
                this.script = script;
                this.setState({ apiLoaded: true });
            })
            .catch((err) => {
                console.error(err.message);
            });
    }
    onSubmit = (values, actions) => {
        let { updateUserProfile, successNotification, clearData } = this.props;
        if (values && values.merchantId && typeof (values.merchantId) == 'string') {
            let merchantIds = values.merchantId
            values.merchantId = merchantIds.split(',')
            delete values['merchantIds']
        }
        let body = {
            email: '',
            firstName: '',
            lastName: '',
            phone: '',
            brandName: '',
            address: '',
        }
        if (values) {
            body['email'] = values['email'];
            body['firstName'] = values['firstName'];
            body['lastName'] = values['lastName'];
            body['phone'] = values['phone'];
            body['brandName'] = values['brandName'];
            body['address'] = values['address'];
        }

        if (values['_id']) {
            body['_id'] = values['_id']
            body['brandId'] = values['brandId']
        }

        updateUserProfile(body, (res, type) => {

            let message
            let details
            if (res && res.details) {
                details = res.details
            }
            if (res && res.respMessage) {
                message = res.respMessage
            } else if (res && res.errorMessage) {
                message = res.errorMessage
            }
            if (actions) {
                actions.setSubmitting(false)
            }
            if (type == 'error') {
                if (actions) {
                    actions.setStatus(message)
                }
                return;
            }
            this.updateUserProfile()
            setTimeout(() => {
                let notification = { ...notificationOpts }
                notification.message = message
                successNotification(notification)
            })
            if (details && details.status && details.status == 'deactivate') {
                clearData(res => {

                })
            }
            this.setState({ isDisabled: true })
        })
    }
    moveToPayment = async () => {
        let { user, successNotification } = this.props
        await this.setState({
            paymentSuccess: false
        })
        paymentSuccess = false;
        this.props.updateUserWithoutBranch({
            stepTwo: true,
            brandId: user.brandId,
            step: 'payment'
        }, async (res, type) => {
            if (type == 'error') {
                let sessionExpiryModal = false
                let sessionExpired = GetSessionValue('sessionExpired')
                if (sessionExpired && (sessionExpired == 'true' || sessionExpired == true)) {
                    sessionExpiryModal = true
                }
                await this.setState({ sessionExpiryModal: sessionExpiryModal })
            }
        })

    }

    updateMandateId = async (self, payerAuthorisationId) => {
        let { user } = self.props;
        if (self.state.payerAuthorisationId) {
            await self.props.createMandateapi({
                payer_authorisations: payerAuthorisationId,
                user: {
                    brandName: user.brandName,
                    brandId: user.brandId,
                    _id: user._id,
                    email: user.email
                }
            }, async (res, type) => {
                self.setState({
                    payerAuthorisationId: ""
                })
                self.props.updateUserWithoutBranch({
                    payerAuthorisationId,
                    brandId: user.brandId
                })
                if (type == 'error') {
                    let sessionExpiryModal = false
                    let sessionExpired = GetSessionValue('sessionExpired')
                    if (sessionExpired && (sessionExpired == 'true' || sessionExpired == true)) {
                        sessionExpiryModal = true
                    }
                    await self.setState({ sessionExpiryModal: sessionExpiryModal })
                }
            })
        }
    }

    gocardlessHandler = () => {
        let gocardlessToken = this.props.settings['gocardlessToken'];
        if (this.state.apiLoaded) {
            let self = this;
            var gocardlessClient = window.GoCardlessClient({
                token: gocardlessToken,
            });
            gocardlessClient.create({
                onSave: function (payerAuthorisationId, confirmCallback, metadata) {
                    // 1. Optional: store the payerAuthorisationId server-side
                    // 2. Mandatory: call confirmCallback to let the Drop-in flow continue
                    confirmCallback();
                    self.setState({
                        payerAuthorisationId
                    })
                },
                onComplete: function (metadata) {
                    paymentSuccess = false;
                    setTimeout(() => {
                        if (self.state.payerAuthorisationId) {
                            self.updateMandateId(self, self.state.payerAuthorisationId)
                        }
                    }, 1000);
                    // The customer completed successfully
                    self.moveToPayment()
                },
                onExit: function (error, metadata) {
                    if (error === undefined) {
                        // The customer left intentionally the Drop-in flow
                        // (for example they closed the modal interface).
                    } else {
                        // The customer left due to unrecoverable error.
                    }
                }
            });
        }
    }

    openSkipPaymentDialog = () => {
        let skipPaymentMessage = "Direct-debit will not be applied. An administrative charge of 1% will be added for processing manual payments. Payments must be settled using the link on the invoice or through account transfer.";
        if(this.props.brandData && this.props.brandData.isAutoDebit == "false") {
            skipPaymentMessage = "Direct-debit will not be applied. Payments must be settled using the link on the invoice or through account transfer.";
        }
        this.setState({ showSkipPayment: true, skipPaymentMessage : skipPaymentMessage })
    }
    
    
    componentDidMount = () => {
        let sessionExpiryModal = false
        let sessionExpired = GetSessionValue('sessionExpired')
        if (sessionExpired && (sessionExpired == 'true' || sessionExpired == true)) {
            sessionExpiryModal = true
        }
        this.setState({ sessionExpiryModal: sessionExpiryModal })
        if (sessionExpiryModal) {
            return
        } else {
            this.loadScript()
            // this.updateUserProfile()
            this.getDataOfUser();
            this.props.getLanguageData((res) => {

            })
        }

    }
    getDataOfUser = async () => {

        let { user } = this.props

        if (user && user['stepTwo'] == true) {
            await this.setState({
                paymentSuccess: false
            })
            paymentSuccess = false
            // this.props.payemnetConfirm();
        }
    }
    updateUserProfile = () => {
        let { getEntityDetails } = this.props
        getEntityDetails(config.entityType, false, async (res, type) => {
            if (type == 'success') {
                await this.setState({ userDetails: res })
            } else {
                let sessionExpiryModal = false
                let sessionExpired = GetSessionValue('sessionExpired')
                if (sessionExpired && (sessionExpired == 'true' || sessionExpired == true)) {
                    sessionExpiryModal = true
                }
                await this.setState({ sessionExpiryModal: sessionExpiryModal })
            }
        })
    }

    formikRef = (e) => {
        this.formik = e
    }

    formikSubmit = () => {
        if (this.formik)
            this.formik.submitForm()
    }

    onToggleEdit = () => {
        let isDisabled = !this.state.isDisabled
        this.setState({ isDisabled })
    }

    getActionBtns = () => {
        if (this.state.isDisabled) {
            return (
                <div className="form-group text-center pb-5 my-5">
                    {/* <a href='https://pay-sandbox.gocardless.com/AL00005JTM64BR'>
                        
                    </a> */}
                    {this.props.user.payerAuthorisationId ? <h4 className='text-success'>Your payment details have been validated successfully</h4> :
                        <div>
                            <button className="btn btn-info btn-fill table_action_btn px-5 ml-auto"
                                style={{ verticalAlign: 'bottom' }}
                                // onClick={this.props.payemnetConfirm}
                                // onClick={this.formikSubmit}
                                id="pay-with-gocardless-button"
                                onClick={() => this.gocardlessHandler()}>
                                Setup Payment with GoCardless
                            </button>  

                            <h5 className='text-secondary pt-4'>We will only invoice you
                                for the customer cashback once the campaign
                                has run successfully and our cardholders have transacted at your store.</h5>
                        </div>

                    }
                </div>
            )
        } else {
            return (
                <div className="form-group text-center">
                    <button className="btn btn-info btn-fill btn-wd" onClick={this.formikSubmit}>Update Details</button>
                </div>
            )
        }
    }

    render() {
        let { user } = this.props;
        let userFrom = sessionStorage.getItem("userFrom");
        return (
            <div>
                <script type="application/javascript" src="https://dropin-sandbox.gocardless.com/script.js"></script>
                <div className="row d-flex justify-content-center">
                    <div className="col-12 col-md-10">
                        <div className="card guestUserCards">
                            <div className='d-flex'>
                                <button className="btn btn-primary table_action_btn px-lg-5 px-md-1  ml-auto mr-3"
                                    style={{ verticalAlign: 'bottom' }}
                                    onClick={this.props.goToCreateCampaign}>
                                    Back
                                </button>
                                <button className="btn btn-primary table_action_btn px-lg-5 px-md-1 mr-3"
                                    style={{ verticalAlign: 'bottom' }}
                                    // disabled={paymentSuccess}
                                    onClick={this.props.payemnetConfirm}>
                                    Next
                                </button>
                                {this.props.brandData.isAutoDebit != "Mandatory" &&
                                    <button className="btn btn-primary table_action_btn px-lg-5 px-md-1"
                                        style={{ verticalAlign: 'bottom' }}
                                        onClick={() => this.openSkipPaymentDialog()}>
                                        Skip
                                    </button>
                                }

                            </div>
                            <div className=" py-5">
                                {/* <button
                                    id="pay-with-gocardless-button"
                                    onClick={this.gocardlessHandler}
                                >
                                    Pay with GoCardless</button> */}
                                {/* <SimpleUserForm
                                    onSubmit={this.onSubmit}
                                    initialValues={user}
                                    options={{ ...this.state }}
                                    formikRef={this.formikRef}
                                    languageData={languageData}
                                /> */}
                                {this.getActionBtns()}
                            </div>
                            {/* session expiry modal */}
                            <SessionExpiryModal
                                isOpen={this.state.sessionExpiryModal}
                                withoutBrach={true}

                            />
                        </div>
                    </div>
                </div>

                <ModalComponent
                    close={e => this.setState({ showSkipPayment: false })}
                    show={this.state.showSkipPayment}
                    title='Skip Direct-Debit'
                    message={this.state.skipPaymentMessage}
                    action='Ok'
                    callback={this.props.skipPayment} />

            </div>
        )
    }
};

export default connect(
    state => ({
        user: state.user,
        settings: state.selectEntities.settingsData
    }),
    dispatch => ({
        updateUserProfile: bindActionCreators(updateUserProfileAction, dispatch),
        updateUserWithoutBranch: bindActionCreators(updateUserProfileActionWithoutBranch, dispatch),
        createMandateapi: bindActionCreators(createMandate, dispatch),
        getLanguageData: bindActionCreators(getLanguageDataAction, dispatch),
        successNotification: bindActionCreators(successNotificationAction, dispatch),
        clearData: bindActionCreators(clearStoreData, dispatch),
        getEntityDetails: bindActionCreators(getEntityDetails, dispatch)
    })
)(UserProfile);

